import { apiService } from '@/core/services';

export const USER_REDEMPTION = {
  getUserRedemption,
  updateCheckUserRedemption
};

const URL = {
  API: 'api',
  V3: 'v3',
  USER_REDEMPTIONS: 'user-redemptions',
  REDEMPTION_DETAIL: 'redemption-detail',
  VERIFY: 'verify'
};

function getUserRedemption(data) {
  let requestOptions = {
    params: {
      user_id: data.userId,
      product_id: data.productId,
      campaign_id: data.campaignId,
      branch_name: data.branchName,
      branch_id: data.branchId,
      merchant_id: data.merchantId,
      total_redeem_point: data.totalPoint
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V3}/${URL.USER_REDEMPTIONS}/${URL.REDEMPTION_DETAIL}`,
    requestOptions
  );
}

function updateCheckUserRedemption(data) {
  let requestBody = {
    pin_verification: data.pinVerification,
    branch_name: data.branchName,
    branch_id: data.branchId,
    merchant_id: data.merchantId,
    campaign_id: data.campaignId,
    product_id: data.productId,
    total_redeem_point: data.totalPoint,
    user_id: data.userId
  };
  return apiService().post(
    `/${URL.API}/${URL.V3}/${URL.USER_REDEMPTIONS}/${URL.VERIFY}`,
    requestBody
  );
}
