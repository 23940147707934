import { USER_REDEMPTION } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';
// import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import currencyFormat from '@/core/filters/currency-format.filter';

/* Define Module Name */
const MODULE_NAME = 'userRedemption/';

/* Define Action Name */
const A_GET_USER_REDEMPTION = 'getUserRedemption';
const A_UPDATE_CHECK_USER_REDEMPTION = 'updateCheckUserRedemption';

/* Define Reset State Action Name */
const A_INITIAL_GET_USER_REDEMPTION_STATE = 'initialGetUserRedemptionState';
const A_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE =
  'initialUpdateCheckUserRedemptionState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USER_REDEMPTION = 'setgetUserRedemption';
const M_UPDATE_CHECK_USER_REDEMPTION = 'setupdateCheckUserRedemption';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_USER_REDEMPTION_STATE = 'setInitialGetUserRedemptionState';
const M_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE =
  'setInitialUpdateCheckUserRedemptionState';

/* Define Export Name */
export const USER_REDEMPTION_GET_USER_REDEMPTION = MODULE_NAME + A_GET_USER_REDEMPTION;
export const USER_REDEMPTION_UPDATE_CHECK_USER_REDEMPTION =
  MODULE_NAME + A_UPDATE_CHECK_USER_REDEMPTION;

/* Define Reset State Name */
export const USER_REDEMPTION_INITIAL_GET_USER_REDEMPTION_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_REDEMPTION_STATE;
export const USER_REDEMPTION_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE;

const state = {
  userRedemption: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  checkUserRedemption: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_USER_REDEMPTION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await USER_REDEMPTION.getUserRedemption(data)
      .then((response) => {
        result = response;
        commit(M_GET_USER_REDEMPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_USER_REDEMPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_UPDATE_CHECK_USER_REDEMPTION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await USER_REDEMPTION.updateCheckUserRedemption(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_CHECK_USER_REDEMPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_CHECK_USER_REDEMPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_USER_REDEMPTION_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_REDEMPTION_STATE);
  },
  [A_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_USER_REDEMPTION](state, result) {
    if (result.code === 0) {
      let d = result.data;
      state.userRedemption = {
        code: result.code,
        complete: true,
        data: {
          countdownTime: result.countdown_time,
          //merchantName: d.merchant_name,
          branchName: d.branch_name,
          product_id: d.product_id,
          totalPoint: currencyFormat(d.total_redeem_point)
          //status: d.status,
        },
        message: null
      };
    } else {
      state.userRedemption = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_CHECK_USER_REDEMPTION](state, result) {
    let d = result;
    state.checkUserRedemption = {
      code: result.code,
      complete: true,
      data: d.data,
      message: d.message
    };
  },
  [M_INITIAL_GET_USER_REDEMPTION_STATE](state) {
    let d = state.userRedemption.data;
    state.userRedemption = {
      code: 0,
      complete: false,
      data: { ...d },
      message: null
    };
  },
  [M_INITIAL_UPDATE_CHECK_USER_REDEMPTION_STATE](state) {
    let data = state.checkUserRedemption.data;
    let code = state.checkUserRedemption.code;
    let message = state.checkUserRedemption.message;

    state.checkUserRedemption = {
      code: code,
      complete: false,
      data: { ...data },
      message: message
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
